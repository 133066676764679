import React, { Component } from 'react';
import {NavLink, withRouter } from 'react-router-dom';

import logo from '../../assets/images/cocktailsguy-logo.svg';


class Fullscreen extends Component {

  state = {
    anim: false,
    animFinalStep: false,
    backToHome: false
  };

  componentDidMount() {
    if(this.props.location.pathname !== "/") {
      this.setState({animFinalStep: true})
    }

    //detect when clicked on prev browser button
    window.onpopstate = e => {
      if(this.props.location.pathname !== "/") {
        this.setState(prevState => ({
          ...prevState,
          animFinalStep: true,
          anim: true,
          backToHome: false
        }));
      }
      else {
        this.setState(prevState => ({
          ...prevState,
          animFinalStep: false,
          anim: false,
          backToHome: true
        }));
      }

    }
  }

  addAnimation = () => {
    if(this.props.location.pathname !== "/") {
      this.setState({
        ...this.state,
        animFinalStep: true
      })
    }
    else {
      this.setState(prevState => ({
        ...prevState,
        animFinalStep: false,
        anim: true
      }));
    }

  };

  backToHome = () => {
    this.setState(prevState => ({
      ...prevState,
      animFinalStep: false,
      anim: false,
      backToHome: true
    }));
  }

  render() {
    let animationTriggered = '';

    if(this.state.anim && !this.state.animFinalStep) {
      animationTriggered = "-animating";
    }
    else if(this.state.animFinalStep) {
      animationTriggered = '-animated'
    }
    else if(this.state.backToHome) {
      animationTriggered = '-backToHome'
    }
    else animationTriggered = ''

    return (
      <div className={animationTriggered}>
        <div className="fullscreen">
          <div className="fullscreen__header">
            Cocktails <br />
            <span> Guy</span>
          </div>
          <div className="fullscreen__content">
            <div className="cocktails">
              <span>c</span>
              <span>o</span>
              <span>c</span>
              <span>k</span>
              <span>t</span>
              <span>a</span>
              <span>i</span>
              <span>l</span>
              <span>s</span>
            </div>
            <div className="cardHighlight1">
              <svg width="100%" height="100%" className="linesOuter">
                <defs>
                  <linearGradient id="gradient" x1="50%" y1="50%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stopColor="#F0AC47" />
                    <stop offset="100%" stopColor="#EFC994" />
                  </linearGradient>
                </defs>
                <line x1="0%" y1="0%" x2="0%" y2="0%" opacity="0" stroke="url(#gradient)" strokeWidth="2" >
                  <animate attributeName="x2" from="0%" to="100%" dur="0.7s" begin="1.3" calcMode="spline" id="first" keyTimes="0;0.5;1" keySplines=".44,.84,.24,.98; .44,.84,.24,.98" fill="freeze" />
                  <animate attributeName="opacity" from="0" to="1" dur="0.7s" begin="1.3" fill="freeze" />
                </line>
                <line x1="100%" y1="0%" x2="100%" y2="0%" opacity="0" stroke="url(#gradient)" strokeWidth="2" >
                  <animate attributeName="y2" from="0%" to="100%" dur="0.7s" begin="1.3" id="second" calcMode="spline" keyTimes="0;0.5;1" keySplines=".44,.84,.24,.98; .44,.84,.24,.98" fill="freeze" />
                  <animate attributeName="opacity" from="0" to="1" dur="0.7s" begin="1.3" fill="freeze"  />
                </line>
                <line x1="100%" y1="100%" x2="100%" y2="100%" opacity="0" stroke="url(#gradient)" strokeWidth="2" >
                  <animate attributeName="x2" from="100%" to="0" dur="0.7s" begin="1.3" id="third" calcMode="spline" keyTimes="0;0.5;1" keySplines=".44,.84,.24,.98; .44,.84,.24,.98" fill="freeze" />
                  <animate attributeName="opacity" from="0" to="1" dur="0.7s" begin="1.3" fill="freeze" />
                </line>
                <line x1="0" y1="100%" x2="0" y2="100%" opacity="0" stroke="url(#gradient)" strokeWidth="2">
                  <animate attributeName="y1" from="100%" to="0" dur="0.7s" begin="1.3" id="fourth" calcMode="spline" keyTimes="0;0.5;1" keySplines=".44,.84,.24,.98; .44,.84,.24,.98" fill="freeze" />
                  <animate attributeName="opacity" from="0" to="1" dur="0.7s" begin="1.3" fill="freeze"  />
                </line>
              </svg>
      
              <svg width="100%" height="100%" className="linesInner">
                <defs>
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%" gradientUnits="userSpaceOnUse">
                    <stop offset="0%" stopColor="#F0AC47" />
                    <stop offset="100%" stopColor="#EFC994" />
                  </linearGradient>
                </defs>
                <line x1="0" y1="0" x2="0" y2="0" opacity="0" stroke="url(#gradient)" strokeWidth="2" >
                  <animate attributeName="x2" from="0" to="100%" dur="0.7s" begin="1.3" calcMode="spline" id="fifth" keyTimes="0;0.5;1" keySplines=".44,.84,.24,.98; .44,.84,.24,.98" fill="freeze" />
                  <animate attributeName="opacity" from="0" to="1" dur="0.7s" begin="1.3" fill="freeze" />
                </line>
                <line x1="100%" y1="0" x2="100%" y2="0" opacity="0" stroke="url(#gradient)" strokeWidth="2" >
                  <animate attributeName="y2" from="0" to="100%" dur="0.7s" begin="1.3" id="sixth" calcMode="spline" keyTimes="0;0.5;1" keySplines=".44,.84,.24,.98; .44,.84,.24,.98" fill="freeze" />
                  <animate attributeName="opacity" from="0" to="1" dur="0.7s" begin="1.3" fill="freeze"  />
                </line>
                <line x1="100%" y1="100%" x2="100%" y2="100%" opacity="0" stroke="url(#gradient)" strokeWidth="2" >
                  <animate attributeName="x2" from="100%" to="0" dur="0.7s" begin="1.3" id="seventh" calcMode="spline" keyTimes="0;0.5;1" keySplines=".44,.84,.24,.98; .44,.84,.24,.98" fill="freeze" />
                  <animate attributeName="opacity" from="0" to="1" dur="0.7s" begin="1.3" fill="freeze" />
                </line>
                <line x1="0" y1="100%" x2="0" y2="100%" opacity="0" stroke="url(#gradient)" strokeWidth="2">
                  <animate attributeName="y1" from="100%" to="0" dur="0.7s" begin="1.3" calcMode="spline" keyTimes="0;0.5;1" keySplines=".44,.84,.24,.98; .44,.84,.24,.98" fill="freeze" />
                  <animate attributeName="opacity" from="0" to="1" dur="0.7s" begin="1.3" fill="freeze"  />
                </line>
              </svg>
      
              <div className="cardHighlight1__content">
                <NavLink to="/" onClick={() => this.backToHome()}><img src={logo} alt="Cocktails Guy" /></NavLink>
                <h1><span>Premium cocktails</span> prepared for the pleasure of your eyes and your taste buds</h1>
                <span>mixed with love</span>
              </div>
            </div>
          </div>
          <div className="fullscreen__nav">
            <nav>
              <ul>
                <li><NavLink to="/recipes" onClick={this.addAnimation}>Recipes</NavLink></li>
                <li><NavLink to="/about" onClick={this.addAnimation}>About me</NavLink></li>
              </ul>
            </nav>
          </div>
        </div>
        {this.props.children}
      </div>
    );
  }
}


export default withRouter(Fullscreen);
