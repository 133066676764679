import * as actionTypes from '../actions/actionTypes';

const initialState = {
  showModal: false,
  cocktail: null
};

const showModal = (state, action) => {
  return {
    ...state,
    showModal: true,
    cocktail: action.cocktail
  }
};

const hideModal = (state, action) => {
  return  {
    ...state,
    showModal: false,
    cocktail: null
  }
};



const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SHOW_MODAL: return showModal(state, action);
    case actionTypes.HIDE_MODAL: return hideModal(state, action);
    default: return state;
  }
};

export default reducer;