export const ADD_ANIM = 'ADD_ANIM';
export const REMOVE_ANIM = 'REMOVE_ANIM';
export const ADD_ANIM_FINAL_STEP = "ADD_ANIM_FINAL_STEP";

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

export const INIT_COCKTAILS_FILTERS = 'INIT_COCKTAILS_FILTERS';
export const SET_ACTIVE_FILTERS = 'SET_ACTIVE_FILTERS';
export const SET_ACTIVE_FILTERS_SUCCESS = 'SET_ACTIVE_FILTERS_SUCCESS';
export const INIT_COCKTAILS_FILTERS_SUCCESS = 'INIT_COCKTAILS_FILTERS_SUCCESS';
export const FETCH_COCKTAILS_START = 'FETCH_COCKTAILS_START';
export const FETCH_COCKTAILS = 'FETCH_COCKTAILS';
export const FETCH_MORE_COCKTAILS = 'FETCH_MORE_COCKTAILS';
export const FETCH_COCKTAILS_SUCCESS = 'FETCH_COCKTAILS_SUCCESS';
export const FETCH_COCKTAILS_ERROR = 'FETCH_COCKTAILS_ERROR';
export const UPDATE_URLS_COCKTAILS_IMGS = 'UPDATE_URLS_COCKTAILS_IMGS';
export const FILTER_COCKTAILS = 'FILTER_COCKTAILS';
export const NO_COCKTAILS_RESULTS = 'NO_COCKTAILS_RESULTS';

export const DISABLE_RESET_FILTERS_BUTTON = 'DISABLE_RESET_FILTERS_BUTTON';
export const ENABLE_RESET_FILTERS_BUTTON = 'ENABLE_RESET_FILTERS_BUTTON';