import React from 'react';
import {Switch, Route, withRouter} from 'react-router-dom';
import {TransitionGroup, CSSTransition} from 'react-transition-group';


import Fullscreen from './components/Fullscreen/Fullscreen';
import asyncComponent from './hoc/asyncComponents/asyncComponents';



const asyncRecipes = asyncComponent(() => {
  return import('./components/Recipes/Recipes')
});

const asyncAbout = asyncComponent(() => {
  return import('./components/About/About')
});


function App({ location }) {
  return (
    <Fullscreen>
      <TransitionGroup className="transition-group">
        <CSSTransition
          key={location.key}
          timeout={{ enter: 300, exit: 300 }}
          classNames={'fade'}
        >
          <section className="route-section">
            <Switch location={location}>
              <Route path="/recipes" component={asyncRecipes} />
              <Route path="/about" component={asyncAbout} />
            </Switch>
          </section>
        </CSSTransition>
      </TransitionGroup>
    </Fullscreen>
  )
}

export default withRouter(App);
