import * as actionTypes from '../actions/actionTypes';

const initialState = {
  cocktails: {},
  loading: false,
  noResults: false,
  filters: {},
  activeFilters: {},
  resetFiltersButtonDisabled: true
};

const fetchCocktailsSuccess = (state, action) => {
  return {
    ...state,
    loading: false,
    cocktails: {
      ...action.cocktailsArray
    },
    noResults: false
  }
};

const fetchCocktailsStart = (state, action) => {
  return {
    ...state,
    loading: true
  }
}

const fetchCocktailsError = (state, action) => {
  return {
    ...state,
    loading: true
  }
}

const filterCocktails = (state, action) => {
  return {
    ...state,
    activeFilters: action.activeFilters,
    noResults: false,

  }
};

const updateUrlsCocktailsImages = (state, action) => {
  return {
    ...state,
    cocktails: {
      ...state.cocktails,
      [action.cocktailId]: {
        ...state.cocktails[action.cocktailId],
        ...action.cocktailData,
        ...action.cocktailImageUrl
      }
    }
  }
};


const noCocktailsResults = (state, action) => {
  return {
    ...state,
    noResults: true
  }
};

const initCocktailsFiltersSuccess = (state, action) => {
  return {
    ...state,
    filters: action.filtersArray
  }
};

const setActiveFiltersSuccess = (state, action) => {
  return {
    ...state,
    activeFilters: action.activeFilters,
    resetFiltersButtonDisabled: false
  }
}

const disableResetFiltersButton = (state, action) => {
  return {
    ...state,
    resetFiltersButtonDisabled: true
  }
}


const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.INIT_COCKTAILS_FILTERS_SUCCESS: return initCocktailsFiltersSuccess(state, action);
    case actionTypes.FILTER_COCKTAILS: return filterCocktails(state, action);
    case actionTypes.SET_ACTIVE_FILTERS_SUCCESS: return setActiveFiltersSuccess(state, action);
    case actionTypes.FETCH_COCKTAILS_START: return fetchCocktailsStart(state, action);
    case actionTypes.FETCH_COCKTAILS_SUCCESS: return fetchCocktailsSuccess(state, action);
    case actionTypes.FETCH_COCKTAILS_ERROR: return fetchCocktailsError(state, action);
    case actionTypes.UPDATE_URLS_COCKTAILS_IMGS: return updateUrlsCocktailsImages(state, action);
    case actionTypes.NO_COCKTAILS_RESULTS: return noCocktailsResults(state, action);
    case actionTypes.FETCH_COCKTAILS_ERROR: return fetchCocktailsError(state, action);
    case actionTypes.DISABLE_RESET_FILTERS_BUTTON: return disableResetFiltersButton(state, action);
    default: return state;
  }
};

export default reducer;