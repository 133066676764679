import * as actionTypes from '../actions/actionTypes';

const initialState = {
  anim: false,
  animFinalStep: false
};

const addAnim = (state, action) => {
  return {
    ...state,
    anim: true
  }
};

const addAnimFinalState = (state, action) => {
  return  {
    ...state,
    animFinalStep: true
  }
};

const removeAnim = (state, action) => {
  return  {
    ...state,
    anim: false,
    animFinalStep: false
  }
};


const reducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.ADD_ANIM: return addAnim(state, action);
    case actionTypes.ADD_ANIM_FINAL_STEP: return addAnimFinalState(state, action);
    case actionTypes.REMOVE_ANIM: return removeAnim(state, action);
    default: return state;
  }
};

export default reducer;